import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { InstagramIcon, GithubIcon, LinkedIcon } from "./Icons"

const Header = ({ siteTitle }) => (
  <header style={{}}>
    <div className="left">
      <Link to="/">
        <div className="site-name">
          <h1>shivam.codes</h1>
          <div className="under-line"></div>
        </div>
      </Link>
      <div className="menu">
        <Link activeClassName="active" to="/work">
          Work
        </Link>
      </div>
    </div>

    <div className="social-links">
      <a href="https://github.com/shivam95">
        <GithubIcon />
      </a>
      <a href="https://www.linkedin.com/in/shivam-marwaha-163091b7/">
        <LinkedIcon />
      </a>
      <a href="https://www.instagram.com/shivam.shoots/">
        <InstagramIcon />
      </a>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
